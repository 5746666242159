<template>
  <section class="parent">
    <template>
      <FSTable
        :fst-id="fstId"
        :headers="getTableHeaders"
        :qso="qso"
        :endpoint="endpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow
                :key="itemIndex"
                className="align-top fst-row fst-row-white"
                textFallbackAlways
              >
                <FSTableRowItem>
                  <div class="section-year">
                    <p class="year">
                      <span v-text="getLogTime(item)" class="date-time" />
                      <br />
                      <span
                        v-text="getLogDate(item)"
                        class="date-date"
                        style=""
                      />
                      <br />
                      #{{ item.id | onlyLastFive }}
                    </p>
                  </div>
                </FSTableRowItem>

                <FSTableRowItem>
                  <div :class="getClassFor('defaultSlot', item)">
                    <slot
                      name="default"
                      v-bind="{
                        defaultSlotClass: getClassFor('defaultSlot', item),
                      }"
                    >
                      <div>
                        <span v-html="getHtmlLogTitle(item)" />
                      </div>

                      <slot
                        v-if="getResponseData(item)"
                        name="expandable"
                        v-bind="{
                          expandableSlotClass: getClassFor(
                            'expandableSlot',
                            item
                          ),
                        }"
                      >
                        <div :class="getClassFor('expandableSlot', item)">
                          <AccordionTransition
                            :expanded="isDetailsExpanded(item)"
                          >
                            <div class="variants secondary relative">
                              <div
                                class="flex justify-end items-center mb-2 cursor-pointer absolute w-full z-10 -ml-7 mt-2"
                                @click="copyToClipBoard(getResponseData(item))"
                              >
                                <i class="far fa-copy  text-gray-50"></i>
                              </div>
                              <div class="logs-content">
                                <vue-json-pretty
                                  :data="getResponseData(item)"
                                  :showIcon="true"
                                  :deep="4"
                                  :showDoubleQuotes="false"
                                />
                              </div>
                            </div>
                          </AccordionTransition>
                        </div>
                      </slot>
                    </slot>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem :text="getLogRelativeTimeString(item)" />
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow
                :key="`fs-table-row-${itemIndex}`"
                className="align-top fst-row fst-row-white"
                textFallbackAlways
              >
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <div class="section-year">
                    <p class="year">
                      <span v-text="getLogTime(item)" class="date-time" />
                      <br />
                      <span
                        v-text="getLogDate(item)"
                        class="date-date"
                        style=""
                      />
                      <br />
                      #{{ item.id | onlyLastFive }}
                    </p>
                  </div>
                </FSTableRowItem>

                <FSTableRowItem>
                  <div :class="getClassFor('defaultSlot', item)">
                    <slot
                      name="default"
                      v-bind="{
                        defaultSlotClass: getClassFor('defaultSlot', item),
                      }"
                    >
                      <div>
                        <span v-html="getHtmlLogTitle(item)" />
                      </div>

                      <slot
                        v-if="getResponseData(item)"
                        name="expandable"
                        v-bind="{
                          expandableSlotClass: getClassFor(
                            'expandableSlot',
                            item
                          ),
                        }"
                      >
                        <div :class="getClassFor('expandableSlot', item)">
                          <AccordionTransition
                            :expanded="isDetailsExpanded(item)"
                          >
                            <div class="variants secondary relative">
                              <div
                                class="flex justify-end items-center mb-2 cursor-pointer absolute w-full z-10 -ml-7 mt-2"
                                @click="copyToClipBoard(getResponseData(item))"
                              >
                                <i class="far fa-copy  text-gray-50"></i>
                              </div>

                              <div class="logs-content">
                                <vue-json-pretty
                                  :data="getResponseData(item)"
                                  :showIcon="true"
                                  :deep="4"
                                  :showDoubleQuotes="false"
                                />
                              </div>
                            </div>
                          </AccordionTransition>
                        </div>
                      </slot>
                    </slot>
                  </div>
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">Appeared At</div>
                    <div class="col-span-5 right-text">
                      {{ getLogRelativeTimeString(item) }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </template>
  </section>
</template>

<script>
import AccordionTransition from '@/components/accordion/AccordionTransition.vue'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
} from '@/components/fs-table'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import dayjs from 'dayjs'

export default {
  name: 'IoTLogTimeline',
  props: {
    endpoint: {
      type: String,
      required: true,
    },
  },
  components: {
    AccordionTransition,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    VueJsonPretty,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      indexMetaData: {
        summary: {},
      },
      qso: { append: '', prepend: '&' },
      fstId: 'IoTLogTimeline',
      tableHeaders: [
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.iotLogs.table.columns.time'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.iotLogs.table.columns.logsDetails'
          ),
          width: '60%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.iotLogs.table.columns.appearedAt'
          ),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.iotLogs.table.columns.time'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleDetailsManagement.diveIntoTab.iotLogs.table.columns.logsDetails'
          ),
          width: '70%',
          sort: null,
        },
      ],
      opened: [],
      filterOptions: [
        {
          key: 'category',
          type: 'checkbox',
          input: [
            { text: 'Heartbeat', value: 'heartbeat' },
            { text: 'Position', value: 'position' },
            { text: 'Error', value: 'error' },
            { text: 'Connection', value: 'connection' },
            { text: 'Lock', value: 'LOCK' },
            { text: 'Unlock', value: 'UNLOCK' },
            { text: 'Cable Lock', value: 'LOCK_CABLE' },
            { text: 'Cable Unlock', value: 'UNLOCK_CABLE' },
            { text: 'Unlock Battery', value: 'UNLOCK_BATTERY' },
            { text: 'Headlight', value: 'HEADLIGHT_SWITCH' },
            { text: 'Taillight', value: 'TAILLIGHT_SWITCH' },
            { text: 'Throttle', value: 'THROTTLE_SWITCH' },
            { text: 'Restart', value: 'RESTART' },
            { text: 'Beep', value: 'BEEP' },
            { text: 'Alarm', value: 'ALARM' },
            { text: 'Speed Limit', value: 'SET_SPEED_LIMIT' },
            { text: 'Speed Mode', value: 'SET_SPEED_MODE' },
            { text: 'Heartbeat Interval', value: 'SET_HEARTBEAT_INTERVAL' },
            { text: 'Position Interval', value: 'SET_POSITION_INTERVAL' },
          ],
          title: 'Category',
        },
      ],
      detailsExpandState: {},
    }
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    copyToClipBoard(data) {
      const jsonString = JSON.stringify(data, null, 2)
      navigator.clipboard
        .writeText(jsonString)
        .then(() => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Successfull',
              text: 'Code Copied',
            },
            500
          )
        })
        .catch((err) => {
          console.error('Error copying JSON to clipboard:', err)
        })
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getLogRelativeTimeString(log) {
      return dayjs(log['@timestamp']).fromNow()
    },
    getLogTime(log) {
      return this.$UTCAwareTime(log['@timestamp'], {
        format: 'hh:mm:ss a',
      })
    },
    getLogDate(log) {
      return this.$UTCAwareTime(log['@timestamp'], {
        format: 'DD MMM, YY',
      })
    },
    getVariant(item) {
      return item.index_name.includes('error') ? 'danger' : 'light'
    },
    getClassFor(term = '', item) {
      const map = {
        defaultSlot: `defaultSlot ${this.getVariant(item)}`,

        expandableSlot: `mt-2`,

        detailsToggle: 'flex items-center text-blue-600 gap-2 text-sm',
      }

      return map[term] || `no-class-found-for:${term}`
    },
    toggleDetails(item) {
      let val
      if (this.detailsExpandState[item.id]) {
        val = false
      } else {
        val = true
      }

      this.$set(this.detailsExpandState, item.id, val)
    },
    isDetailsExpanded(item) {
      return this.detailsExpandState[item.id] || true
    },
    getHtmlLogTitle(item) {
      let logType = ''
      let subtext = ''
      if (item.index_name.includes('heartbeat')) {
        logType = 'Heartbeat Data'
      } else if (item.index_name.includes('position')) {
        logType = item.is_valid ? 'Position Data' : 'Invalid Position Data'
      } else if (item.index_name.includes('cmd_request')) {
        logType = 'Command Sent'
        subtext = 'category' in item ? item['category'] : item['input_cmd']
      } else if (item.index_name.includes('cmd_response')) {
        logType = 'Response Received'
        subtext = 'category' in item ? item['category'] : item['response_type']
      } else if (item.index_name.includes('error')) {
        logType = 'Error Happened'
        subtext = item['error_repr']
      } else if (item.index_name.includes('connection')) {
        logType = 'Connection Status'
        subtext = item['is_connected'] ? 'Connected' : 'Disconnected'
      } else {
        logType = 'Unknown'
      }

      return `<b class="font-bold">${logType}</b> ${
        subtext !== '' ? `| ${subtext}` : ''
      }`
    },
    getPartialResponse(item, fields = []) {
      if (!fields.length) return null
      let payload = {}
      for (const f of fields) {
        payload[f] = item[f]
      }
      return payload
    },
    getResponseData(item) {
      var fields_for_payload = []
      if (item.index_name.includes('heartbeat')) {
        fields_for_payload = [
          'raw_frame',
          'battery_pct',
          'is_charging',
          'is_locked',
          'network_level',
        ]
      } else if (item.index_name.includes('position')) {
        fields_for_payload = [
          'raw_frame',
          'pos_accuracy',
          'location',
          'is_valid',
        ]
      } else if (item.index_name.includes('cmd_request')) {
        fields_for_payload = ['input_cmd', 'raw_frame', 'token', 'req_id']
      } else if (item.index_name.includes('cmd_response')) {
        fields_for_payload = ['raw_data', 'response_type', 'parsed_data']
      } else if (item.index_name.includes('error')) {
        fields_for_payload = ['error_msg', 'error_trace']
      } else if (item.index_name.includes('connection')) {
        fields_for_payload = ['imei', 'is_connected']
      } else {
        return null
      }

      return this.getPartialResponse(item, fields_for_payload)
    },
    getEventIcon(item) {
      if (item.index_name.includes('heartbeat')) {
        return 'VEHICLE_ACTIVE'
      }

      if (item.index_name.includes('position')) {
        return 'NEARBY_API'
      }

      if (item.index_name.includes('cmd_request')) {
        return 'RIDER_PROFILE_FETCH'
      }

      if (item.index_name.includes('cmd_response')) {
        return 'RIDER_PROFILE_FETCH'
      }

      if (item.index_name.includes('error')) {
        return 'BAD_REQUEST_500'
      }

      if (item.index_name.includes('connection')) {
        return item.is_connected ? 'VEHICLE_ACTIVE' : 'VEHICLE_INACTIVE'
      }

      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.section-year {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 15px 15px 0;
  font-weight: bold;
  font-size: 12px;
  .year {
    margin: 0;
    .date-time {
      color: #2e2e39;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .date-date {
      color: #909099;
      font-size: 13px;
      font-weight: 400;
    }
  }
}

.font-regular {
  font-weight: 400 !important;
}

.variants {
  @apply p-2 mt-3;
  border-radius: 7px;
  word-wrap: break-word;

  &.light {
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
  }

  &.secondary {
    background: #f1f1f1;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
  }

  &.warning {
    background: #f9f0d7;
    border: 1px solid #f2c816;
    border-radius: 8px;
  }
}

@media (max-width: 300px) {
  .variants {
    width: 150px;
  }
}
@media (min-width: 301px) and (max-width: 400px) {
  .variants {
    width: 200px;
  }
}
@media (min-width: 401px) and (max-width: 500px) {
  .variants {
    width: 250px;
  }
}
@media (min-width: 501px) and (max-width: 600px) {
  .variants {
    width: 300px;
  }
}
@media (min-width: 601px) and (max-width: 700px) {
  .variants {
    width: 350px;
  }
}
@media (min-width: 701px) and (max-width: 800px) {
  .variants {
    width: 400px;
  }
}
@media (min-width: 801px) and (max-width: 930px) {
  .variants {
    width: 450px;
  }
}
@media (min-width: 930px) and (max-width: 1024px) {
  .variants {
    width: 550px;
  }
}
@media (min-width: 1025px) {
  .variants {
    @apply max-w-4xl;
  }
}
.logs-content {
  color: #ff00a2;
  padding: 5px 8px;
  background: #000000;
  border: 1px solid #ffffff;
  border-radius: 7px;
  width: 100%;
  overflow: auto;
}

.parent {
  background-color: white;
}

.wrap-text {
  word-wrap: break-word;
}
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>
