var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"parent"},[[_c('FSTable',{attrs:{"fst-id":_vm.fstId,"headers":_vm.getTableHeaders,"qso":_vm.qso,"endpoint":_vm.endpoint,"text-fallback-always":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":_vm.fstId,"slot-width":slotWidth,"options":_vm.filterOptions}})],1)]}},{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"className":"align-top fst-row fst-row-white","textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"section-year"},[_c('p',{staticClass:"year"},[_c('span',{staticClass:"date-time",domProps:{"textContent":_vm._s(_vm.getLogTime(item))}}),_c('br'),_c('span',{staticClass:"date-date",domProps:{"textContent":_vm._s(_vm.getLogDate(item))}}),_c('br'),_vm._v(" #"+_vm._s(_vm._f("onlyLastFive")(item.id))+" ")])])]),_c('FSTableRowItem',[_c('div',{class:_vm.getClassFor('defaultSlot', item)},[_vm._t("default",function(){return [_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getHtmlLogTitle(item))}})]),(_vm.getResponseData(item))?_vm._t("expandable",function(){return [_c('div',{class:_vm.getClassFor('expandableSlot', item)},[_c('AccordionTransition',{attrs:{"expanded":_vm.isDetailsExpanded(item)}},[_c('div',{staticClass:"variants secondary relative"},[_c('div',{staticClass:"flex justify-end items-center mb-2 cursor-pointer absolute w-full z-10 -ml-7 mt-2",on:{"click":function($event){_vm.copyToClipBoard(_vm.getResponseData(item))}}},[_c('i',{staticClass:"far fa-copy  text-gray-50"})]),_c('div',{staticClass:"logs-content"},[_c('vue-json-pretty',{attrs:{"data":_vm.getResponseData(item),"showIcon":true,"deep":4,"showDoubleQuotes":false}})],1)])])],1)]},null,{
                        expandableSlotClass: _vm.getClassFor(
                          'expandableSlot',
                          item
                        ),
                      }):_vm._e()]},null,{
                      defaultSlotClass: _vm.getClassFor('defaultSlot', item),
                    })],2)]),_c('FSTableRowItem',{attrs:{"text":_vm.getLogRelativeTimeString(item)}})],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"className":"align-top fst-row fst-row-white","textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',[_c('div',{staticClass:"section-year"},[_c('p',{staticClass:"year"},[_c('span',{staticClass:"date-time",domProps:{"textContent":_vm._s(_vm.getLogTime(item))}}),_c('br'),_c('span',{staticClass:"date-date",domProps:{"textContent":_vm._s(_vm.getLogDate(item))}}),_c('br'),_vm._v(" #"+_vm._s(_vm._f("onlyLastFive")(item.id))+" ")])])]),_c('FSTableRowItem',[_c('div',{class:_vm.getClassFor('defaultSlot', item)},[_vm._t("default",function(){return [_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getHtmlLogTitle(item))}})]),(_vm.getResponseData(item))?_vm._t("expandable",function(){return [_c('div',{class:_vm.getClassFor('expandableSlot', item)},[_c('AccordionTransition',{attrs:{"expanded":_vm.isDetailsExpanded(item)}},[_c('div',{staticClass:"variants secondary relative"},[_c('div',{staticClass:"flex justify-end items-center mb-2 cursor-pointer absolute w-full z-10 -ml-7 mt-2",on:{"click":function($event){_vm.copyToClipBoard(_vm.getResponseData(item))}}},[_c('i',{staticClass:"far fa-copy  text-gray-50"})]),_c('div',{staticClass:"logs-content"},[_c('vue-json-pretty',{attrs:{"data":_vm.getResponseData(item),"showIcon":true,"deep":4,"showDoubleQuotes":false}})],1)])])],1)]},null,{
                        expandableSlotClass: _vm.getClassFor(
                          'expandableSlot',
                          item
                        ),
                      }):_vm._e()]},null,{
                      defaultSlotClass: _vm.getClassFor('defaultSlot', item),
                    })],2)])],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Appeared At")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getLogRelativeTimeString(item))+" ")])])])]):_vm._e()]:_vm._e()]})]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }